import { NavLink } from 'react-router-dom'
import styled, { css } from 'styled-components'

const defaultProps = {
  theme: { palette: { text: { primary: null }, systemBlue: null, pickupSystemBlue: null } },
  $selected: false,
}

const Logo = styled(NavLink)(props => {
  const {
    $selected,
    theme: {
      palette: {
        text: { primary },
        systemBlue,
        pickupSystemBlue,
      },
    },
  } = props || defaultProps
  return css`
    display: inline-flex;
    height: 55px;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    text-decoration: none;
    color: ${$selected ? systemBlue : primary};
    font-size: 1.0625rem;
    font-weight: 700;
    border-bottom: 3px solid ${$selected ? systemBlue : 'transparent'};
    &:hover,
    .selected {
      color: ${systemBlue};
      background-color: ${pickupSystemBlue};
      border-bottom-color: ${systemBlue};
    }
    background-color: ${$selected ? pickupSystemBlue : 'transparent'};
  `
})

export default Logo
