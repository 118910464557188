import styled, { css } from 'styled-components'

const Col = styled.div`
  display: flex;
  flex-direction: column;
  ${props =>
    props.padding &&
    css`
      padding: ${props.padding};
    `}
`

export default Col
