import styled from 'styled-components'

// TODO need breakpoints for mobile,tablet

const Container = styled.div`
  background-color: ${props => props.theme.palette.dualGreen};
  border-bottom: 3px solid ${props => props.theme.palette.common.white};
  box-shadow: ${props => props.theme.shape.boxShadow};
  display: flex;
  height: 106px;
  z-index: 2;
  @media (min-width: 1024px) {
    height: 53px;
  }

  left: 0;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;

  top: 0;
  width: 100%;
  max-width: 120rem;
`

export default Container
