import styled from 'styled-components'

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 139px;
  width: 130px;
  background-color: ${props => props.theme.palette.common.white};
  border-radius: ${props => props.theme.shape.borderRadius};
  box-shadow: ${props => props.theme.shape.boxShadow};
  z-index: 2;
`

export default LogoWrapper
