import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { RadioSelected, RadioUnselected } from '../../../assets/icons'

const useStyles = makeStyles(theme => ({
  red: {
    color: theme.palette.red,
  },
  orange: {
    color: theme.palette.error.main,
  },
  green: {
    color: theme.palette.primary.main,
  },
  blue: {
    color: theme.palette.secondary.main,
  },
  row: {
    cursor: 'pointer',
    marginBottom: theme.spacing(1),
  },
}))

const FinalNoticeRadioButton = ({ value, onChange, notice, isTitle }) => {
  const { t } = useTranslation('common')
  const classes = useStyles()
  const getClassName = val => {
    let returnValue = classes.grey
    switch (val) {
      case 'offen':
        returnValue = classes.red
        break
      case 'Testat angefordert':
        returnValue = classes.red
        break
      case 'zwischengespeichert':
        returnValue = classes.orange
        break
      case 'gesendet':
        returnValue = classes.green
        break
      case 'fakturiert':
        returnValue = classes.blue
        break
      default:
        returnValue = classes.grey
        break
    }
    return returnValue
  }

  return isTitle ? (
    <Grid container spacing={1}>
      <Grid item md={1} />
      <Grid item md={2}>
        <Typography variant="caption">{t('from')}</Typography>
      </Grid>
      <Grid item md={2}>
        <Typography variant="caption">{t('till')}</Typography>
      </Grid>
      <Grid item md={3}>
        <Typography variant="caption">{t('finalNotice:step1SubmitNotice')}</Typography>
      </Grid>
      <Grid item md={3}>
        <Typography variant="caption">{t('finalNotice:step2SubmitCertificate')}</Typography>
      </Grid>
    </Grid>
  ) : (
    <Grid className={classes.row} container spacing={1} onClick={() => onChange(notice.id)}>
      <Grid item md={1}>
        {value === notice.id ? <RadioSelected /> : <RadioUnselected />}
      </Grid>
      <Grid item md={2}>
        <Typography variant="body1">{notice.from}</Typography>
      </Grid>
      <Grid item md={2}>
        <Typography variant="body1">{notice.to}</Typography>
      </Grid>
      <Grid item md={3}>
        <div className={getClassName(notice.finalStatus)}>
          <Typography variant="body1">{t(notice.finalStatus, { ns: 'viewReport' })}</Typography>
        </div>
      </Grid>
      <Grid item md={4}>
        <div className={getClassName(notice.certificateStatus)}>
          <Typography variant="body1">
            {t(notice.certificateStatus, { ns: 'viewReport' })}
          </Typography>
        </div>
      </Grid>
    </Grid>
  )
}
FinalNoticeRadioButton.propTypes = {
  value: PropTypes.string,
  notice: PropTypes.object,
  onChange: PropTypes.func,
  isTitle: PropTypes.bool,
}
FinalNoticeRadioButton.defaultProps = {
  value: undefined,
  notice: {},
  onChange: undefined,
  isTitle: undefined,
}

export default FinalNoticeRadioButton
