import React from 'react'
import { Link as RLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  link: {
    textDecoration: 'none',
    color: theme.palette.secondary.main,
    '&:visited': {
      color: theme.palette.secondary.main,
    },
    '&:hover': {
      color: theme.palette.linkHover,
    },
  },
  bold: {
    fontWeight: 'bold',
  },
}))

const Link = ({ to, children, className, bold }) => {
  const classes = useStyles()
  return (
    <RLink to={to} className={clsx(classes.link, className, bold && classes.bold)}>
      {children}
    </RLink>
  )
}
Link.propTypes = {
  to: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.string,
  bold: PropTypes.bool,
}
Link.defaultProps = {
  to: undefined,
  children: null,
  className: null,
  bold: false,
}

export default Link
