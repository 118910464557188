import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Link, Text } from '../../atoms'
import Container from './Container'

const useStyles = makeStyles(theme => ({
  link: {
    color: theme.palette.secondary.main,
    padding: '10px 0',
    fontSize: '0.875rem',
    lineHeight: '1',
  },
}))

const NewsItem = ({ date, text, to }) => {
  const classes = useStyles()

  return (
    <Container>
      <Text $italic $color="textGrey" fontSize="0.875rem" lineHeight="1.125" fontWeight="400">
        {date}
      </Text>
      <Link to={to} className={classes.link} bold>
        {text}
      </Link>
    </Container>
  )
}

NewsItem.propTypes = {
  date: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
}

export default NewsItem
