import React, { useState, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Customer, HeaderAdvancedNotice, HeaderFinalNotice, Position } from './types'

export const defaultContextValue: ICustomerContext = {
  customers: [],
  setCustomers: () => {},
  setData: () => {},
  setCustomer: () => {},
  getCustomer: () => undefined,
}

const CustomerContext = React.createContext<ICustomerContext>(defaultContextValue)

export interface ICustomerContextData {
  headerFinalNotices?: HeaderFinalNotice[]
  headerAdvancedNotices?: HeaderAdvancedNotice[]
  positions?: Position[]
}

export interface ICustomerContext {
  data?: ICustomerContextData
  customers?: Customer[]
  setCustomers: React.Dispatch<React.SetStateAction<Customer[] | undefined>>
  setData: React.Dispatch<React.SetStateAction<ICustomerContextData | undefined>>
  setCustomer(c: Customer | undefined): void
  getCustomer(id: string): Customer | undefined
}

export const CustomerProvider = ({ children }) => {
  const [customers, setCustomers] = useState<Customer[] | undefined>(defaultContextValue.customers)
  const setCustomer = useCallback(
    (customer: Customer) => {
      setCustomers(cs => cs?.map(c => (c.id === customer.id ? customer : c)))
    },
    [setCustomers]
  )
  const getCustomer = useCallback((id: string) => customers?.find(c => c.id === id), [customers])

  const [data, setData] = useState<ICustomerContextData | undefined>({})
  const context = useMemo(
    () => ({
      customers,
      setCustomers,
      data,
      setData,
      setCustomer,
      getCustomer,
    }),
    [customers, setCustomers, data, setData, setCustomer, getCustomer]
  )

  return <CustomerContext.Provider value={context}>{children}</CustomerContext.Provider>
}
CustomerProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default CustomerContext
