import React from 'react'
import { PropTypes } from 'prop-types'
import { LocaleProvider } from './utils/LocaleContext'

const Localization = ({ children }) => <LocaleProvider>{children}</LocaleProvider>

Localization.propTypes = {
  children: PropTypes.any.isRequired,
}

export default Localization
