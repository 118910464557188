import styled, { css } from 'styled-components'

const defaultProps = {
  theme: { palette: { text: { primary: null }, systemBlue: null, pickupSystemBlue: null } },
}

const StyledButton = styled.button(props => {
  const {
    theme: {
      fontFamily,
      palette: {
        text: { primary },
        systemBlue,
        pickupSystemBlue,
      },
    },
  } = props || defaultProps
  return css`
    display: inline-flex;
    height: 55px;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    text-decoration: none;
    color: ${primary};
    font-size: 1.0625rem;
    font-weight: 700;
    font-family: ${fontFamily};
    border: none;
    border-bottom: 3px solid transparent;
    &:hover {
      color: ${systemBlue};
      background-color: ${pickupSystemBlue};
      border-bottom-color: ${systemBlue};
    }
    background-color: transparent;
    cursor: pointer;
  `
})

export default StyledButton
