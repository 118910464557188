import React from 'react'
import { Select as MSelect, SelectProps } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

interface Props extends SelectProps {
  [x: string]: any
}

const CustomSelectInGrid: React.FC<Props> = props => {
  const SelectInGrid = withStyles(() => ({
    select: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      padding: '10px',
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
  }))(MSelect)
  return <SelectInGrid {...props} />
}

export default CustomSelectInGrid
