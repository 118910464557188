import React, { useState, Fragment } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation, Trans } from 'react-i18next'
import PropTypes from 'prop-types'
import { yupResolver } from '@hookform/resolvers/yup'
import { Grid, Typography, Button as MuiButton } from '@material-ui/core'
import { Button, Input } from '../../atoms'
import { useStyles, validationSchema, errorHandler, contractsCount } from './utils'

const RegisterForm = ({ onSubmit }) => {
  const [count, setCount] = useState(1)
  const classes = useStyles()
  const { t } = useTranslation('auth')

  const methods = useForm({
    resolver: yupResolver(validationSchema),
  })

  const {
    handleSubmit,
    formState: { errors },
    register,
    control,
  } = methods

  const inputProps = {
    small: true,
    fullWidth: true,
    className: classes.input,
    labelClassName: classes.label,
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid xs={12} item>
          <Typography variant="h2">{t('register:register')}</Typography>
        </Grid>
        <Grid xs={12} sm={6} item>
          <Input
            {...register('firstName')}
            {...inputProps}
            label={t('firstName')}
            error={errorHandler(errors, 'firstName')}
            helperText={t(errorHandler(errors, 'firstName'))}
            className={classes.input}
          />
        </Grid>
        <Grid xs={12} sm={6} item>
          <Input
            {...register('lastName')}
            {...inputProps}
            label={t('lastName')}
            error={errorHandler(errors, 'lastName')}
            helperText={t(errorHandler(errors, 'lastName'))}
            className={classes.input}
          />
        </Grid>
        <Grid xs={12} sm={6} item>
          <Input
            {...register('email')}
            {...inputProps}
            label={t('email')}
            error={errorHandler(errors, 'email')}
            helperText={t(errorHandler(errors, 'email'))}
            className={classes.input}
          />
        </Grid>
        <Grid xs={12} sm={6} item>
          <Input
            {...register('phone')}
            {...inputProps}
            label={t('phone')}
            error={errorHandler(errors, 'phone')}
            helperText={t(errorHandler(errors, 'phone'))}
            className={classes.input}
          />
        </Grid>

        {contractsCount(count)?.map(i => (
          <Fragment key={i}>
            <Grid xs={12} md={6} item key={`contractCompany${i}`}>
              <Controller
                control={control}
                name={`requestedCustomers[${i}].companyName`}
                render={({ field: { onChange, value, ref } }) => (
                  <Input
                    {...inputProps}
                    ref={ref}
                    onChange={onChange}
                    value={value}
                    label={t('register:contractCompany')}
                    error={errorHandler(errors, 'requestedCustomers.companyName', i)}
                    helperText={t(errorHandler(errors, 'requestedCustomers.companyName', i))}
                    required
                  />
                )}
              />
            </Grid>
            <Grid xs={12} md={6} item key={`contractNumber${i}`}>
              <Controller
                control={control}
                name={`requestedCustomers[${i}].agreementNo`}
                render={({ field: { onChange, value, ref } }) => (
                  <Input
                    {...inputProps}
                    ref={ref}
                    onChange={onChange}
                    value={value}
                    label={t('register:contractNumber')}
                    error={errorHandler(errors, 'requestedCustomers.agreementNo', i)}
                    helperText={t(errorHandler(errors, 'requestedCustomers.agreementNo', i))}
                  />
                )}
              />
            </Grid>
          </Fragment>
        ))}
        <Grid xs={12} item>
          <div className={classes.linkLikeButton}>
            <MuiButton onClick={() => setCount(count + 1)}>
              <Typography variant="caption">{t('register:addAnotherContractNo')}</Typography>
            </MuiButton>
          </div>
        </Grid>
        <Grid xs={12} md={6} item>
          <Input
            {...inputProps}
            {...register('password')}
            label={t('register:password')}
            error={errorHandler(errors, 'password')}
            helperText={t(errorHandler(errors, 'password'))}
            type="password"
          />
        </Grid>
        <Grid xs={12} md={6} item>
          <Input
            {...inputProps}
            {...register('passwordConfirmation')}
            label={t('register:passwordConfirmation')}
            error={errorHandler(errors, 'passwordConfirmation')}
            helperText={t(errorHandler(errors, 'passwordConfirmation'))}
            type="password"
          />
        </Grid>
        <Grid item>
          <Button type="submit" color="primary" className={classes.button}>
            {t('register:registerAction')}
          </Button>
        </Grid>
        <Grid xs={12} md={12} item>
          <Typography variant="body2" className={classes.link}>
            <Trans
              t={t}
              i18nKey="register:agreementText"
              components={{
                l1: (
                  <a target="_blank" rel="noopener noreferrer" href={t('common:termsLink')}>
                    &nbsp;
                  </a>
                ),
                l2: (
                  <a target="_blank" rel="noopener noreferrer" href={t('common:privacyLink')}>
                    &nbsp;
                  </a>
                ),
              }}
            />
          </Typography>
        </Grid>
        <Grid xs={12} item>
          <Typography variant="body2">{t('auth:mandatoryFields')}</Typography>
        </Grid>
      </Grid>
    </form>
  )
}
RegisterForm.propTypes = {
  onSubmit: PropTypes.func,
}
RegisterForm.defaultProps = {
  onSubmit: () => {},
}

export default RegisterForm
