export const locales = {
  de: {
    moment: 'de',
    name: 'de',
  },
  en: {
    moment: 'en-gb',
    name: 'en',
  },
}

const loadLocale = async locale => {
  if (locales[locale]) {
    await import(`moment/locale/${locales[locale].moment}`)
    return { moment: locales[locale].moment }
  }
  return loadLocale('en')
}

export default loadLocale
