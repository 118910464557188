import React, { useState } from 'react'
import { initReactI18next } from 'react-i18next'
import PropTypes from 'prop-types'
import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import moment from 'moment'
import { storeLanguage, getStoredLanguage } from './helper'
import loadLocale, { locales } from './locales'

const availableLanguages = [
  {
    value: 'de',
    label: 'DE',
  },
  {
    value: 'en',
    label: 'EN',
  },
]

const defaultLanguage = getStoredLanguage() || 'de'
export const defaultContextValue = () => ({
  language: locales[defaultLanguage],
  setLanguages: () => {},
  availableLanguages,
})

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: defaultLanguage,
    ns: [
      'common',
      'auth',
      'adancedNotice',
      'finalNotice',
      'login',
      'register',
      'contracts',
      'profile',
      'sidebar',
      'table',
      'translation',
      'viewReport',
    ],
    defaultNS: 'common',
    interpolation: {
      escapeValue: false,
    },
  })

const LocaleContext = React.createContext(defaultContextValue())

export const LocaleProvider = ({ children }) => {
  const [language, setStateLanguage] = useState(defaultContextValue().language)
  const setLanguage = newLang => {
    setStateLanguage(locales[newLang])
    storeLanguage(newLang)
    i18n.changeLanguage(locales[newLang]?.name)
  }
  const context = {
    language,
    setLanguage,
    availableLanguages,
  }

  loadLocale(language?.name).then(localeObject => {
    moment.locale(localeObject?.moment)
  })
  return <LocaleContext.Provider value={context}>{children}</LocaleContext.Provider>
}
LocaleProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LocaleContext
