import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Typography, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Input } from '../../atoms'
import FormItem from './FormItem'

const useStyles = makeStyles(theme => ({
  bold: { fontWeight: 600 },
  label: {
    padding: 0,
  },
  colorEnabled: {
    '&.Mui-disabled,.MuiInputBase-input': {
      color: `${theme.palette.grey[200]} !important`,
    },
    '& > .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: `${theme.palette.grey[500]}`,
    },
  },
  maxWidth100: {
    maxWidth: '100% !important',
  },
}))

const FormGroup = ({ fields, sumTitle, data, startIndex }) => {
  const { t } = useTranslation('common')
  const classes = useStyles()
  const { watch } = useFormContext()

  const renderSum = () => {
    const sum = fields?.reduce((a, field, index) => {
      let returnVal = 0
      if (field.form) {
        returnVal = watch(`positions[${index + startIndex}].amount`)
      }
      returnVal = parseFloat(returnVal)
      returnVal = Number.isNaN(returnVal) ? 0 : returnVal
      return a + returnVal
    }, 0)

    return (
      <Grid
        container
        alignItems="center"
        spacing={2}
        direction="row"
        className={classes.maxWidth100}
      >
        <Grid item sm={12} md={6} lg={4}>
          <Typography color="textSecondary" className={classes.bold}>
            {sumTitle}
          </Typography>
        </Grid>
        <Grid item sm={12} md={6} lg={1} />

        <Grid item sm={12} md={6} lg={3}>
          <div className={classes.colorEnabled}>
            <Input
              name="sum"
              value={sum}
              type="number"
              placeholder={t('amountInKilo')}
              small
              fullWidth
              labelClassName={classes.label}
              disabled
              decimalScale={0}
              className={classes.colorEnabled}
            />
          </div>
        </Grid>
      </Grid>
    )
  }
  return (
    <>
      {fields?.map((field, index) => (
        <FormItem key={field.position} row={field} index={index + startIndex} data={data} />
      ))}
      {sumTitle && renderSum()}
    </>
  )
}

FormGroup.propTypes = {
  data: PropTypes.array.isRequired,
  fields: PropTypes.array.isRequired,
  startIndex: PropTypes.number.isRequired,
  sumTitle: PropTypes.string,
}
FormGroup.defaultProps = {
  sumTitle: null,
}

export default FormGroup
