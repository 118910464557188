import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { debounce } from 'lodash'
import { Download, UploadOrange } from '../../../assets/icons'
import colors from '../../../utils/colors'
import { useFetchExport } from '../../../utils/hooks'

const useStyles = makeStyles(theme => ({
  divStyle: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    color: colors.secondaryColor,
    textDecoration: 'none !important',
  },
  linkLikeButton: {
    '& > *': {
      '& .MuiTypography-h3': {
        color: 'theme.palette.secondary.main',
      },
      minWidth: 0,
      margin: '0px 0px 35px 0px',
      backgroundColor: 'transparent',
      border: 'none',
      padding: 0,
      textTransform: 'none',
      '&:hover': {
        backgroundColor: theme.palette.common.white,
        boxShadow: 'none',
        border: 'none',
      },
    },
  },
  orange: { color: colors.gSystemOrange },
  marginLeft: { marginLeft: 5 },
}))

const DownloadComponent = ({ url, name, labelClassName, onClick, isPublicFile }) => {
  const { t } = useTranslation('common')
  const classes = useStyles()
  const [load, setLoad] = useState(false)
  const [{ error, loading }, doFetch] = useFetchExport({ headers: {} })

  if (error) console.error(error)

  const throttledFetch = debounce(
    () => {
      doFetch(url)
      if (!loading) setLoad(false)
    },
    500,
    { trailing: true, maxWait: 5000, leading: false }
  )

  return url && name ? (
    <div
      key={name}
      role="button"
      className={classes.divStyle}
      onKeyDown={() => {}}
      tabIndex="-1"
      onClick={e => {
        if (!isPublicFile) {
          e.stopPropagation()
          setLoad(true)
          throttledFetch()
        }
        onClick?.()
      }}
      disabled={load}
    >
      <Download />
      <div className={clsx(classes.marginLeft, labelClassName)}>{name}</div>
    </div>
  ) : (
    <div className={clsx(classes.divStyle, classes.orange)}>
      <UploadOrange />
      <div className={clsx(classes.marginLeft, labelClassName)}>{t('noDocument')}</div>
    </div>
  )
}

DownloadComponent.propTypes = {
  name: PropTypes.string,
  url: PropTypes.string,
  isPublicFile: PropTypes.bool,
  onClick: PropTypes.func,
  labelClassName: PropTypes.string,
}
DownloadComponent.defaultProps = {
  name: null,
  url: null,
  isPublicFile: false,
  onClick: undefined,
  labelClassName: undefined,
}

export default DownloadComponent
