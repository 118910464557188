import styled, { css } from 'styled-components'
import { textColor } from '../../../utils/stylesHelper'

const Text = styled.p(
  props => css`
    ${props.lineHeight
      ? css`
          line-height: ${props.lineHeight};
        `
      : css`
          line-height: 1.6;
        `}
    ${props.fontSize
      ? css`
          font-size: ${props.fontSize};
        `
      : css`
          font-size: 1rem;
        `}
    ${props.$bold &&
    css`
      font-weight: bold;
    `}
    ${props.$bolder &&
    css`
      font-weight: bolder;
    `}
    ${props.fontWeight &&
    css`
      font-weight: ${props.fontWeight};
    `}
  ${props.$disabled &&
    css`
      color: ${props.theme.palette?.text?.disabled};
    `}
  ${props.$italic &&
    css`
      font-style: italic;
    `}
  ${props.$color && textColor(props.$color)}

  ${props.$center &&
    css`
      text-align: center;
    `}
  `
)

export default Text
