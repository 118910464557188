import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography } from '@material-ui/core'

const CompanyItem = ({ value: items, label }) => (
  <Grid container direction="column" spacing={1}>
    <Grid item>
      <Typography variant="h2">{label}</Typography>
    </Grid>
    {items.map(item => {
      const { value, label: itemLabel } = item
      return (
        <Grid container item spacing={1}>
          <Grid item xs={6} md={4}>
            <Typography variant="body1">{itemLabel}</Typography>
          </Grid>
          <Grid item xs={6} md={8}>
            <Typography variant="body1">{value}</Typography>
          </Grid>
        </Grid>
      )
    })}
  </Grid>
)

CompanyItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.string,
    })
  ).isRequired,
}

export default CompanyItem
