import React, { useState, useEffect } from 'react'
import { FormControl, Grid, MenuItem, FormLabel } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ArrowDown } from 'assets/icons'
import clsx from 'clsx'
import CustomSelectInGrid from './CustomSelectInGrid'

export interface ISelectItem {
  value: any
  label: string
}

interface Props {
  children?: React.ReactNode
  disabled?: boolean
  items?: ISelectItem[]
  label?: string
  multiple?: boolean
  borderless?: boolean
  onChange?(value?: any): void
  value?: any
  placeholder?: string
  selectClassName?: string
  labelClassName?: string
  labelLeft?: boolean
  required?: boolean
}

const useStyles = makeStyles(() => ({
  root: {
    '& label + .MuiInput-formControl': {
      marginTop: 12,
    },
  },
  borderless: {
    fontWeight: 'bold',
    '& fieldset': {
      border: 'none',
    },
  },
}))

const ArrowDownComponent: React.FC<any> = props => (
  <ArrowDown {...props} style={{ top: 'inherit' }} />
)

const SelectInGrid = React.forwardRef<typeof FormControl, Props>(
  ({
    children,
    disabled,
    items,
    label,
    multiple,
    onChange,
    value: propValue,
    placeholder,
    borderless,
    selectClassName,
    labelClassName,
    labelLeft,
    required,
  }) => {
    const classes = useStyles()
    const [value, setValue] = useState(propValue)

    useEffect(() => {
      if (propValue !== value) setValue(propValue)
    }, [propValue, setValue, value])

    const placeholderObject = { label: <em>{placeholder}</em>, value: '' }
    const itemsWithPlaceholder = placeholder ? [placeholderObject, ...(items || [])] : items
    const handleChange = (event: any) => {
      const { target } = event
      const { value: newValue } = target || {}
      onChange?.(newValue)
    }

    return (
      <Grid
        container
        {...(labelLeft
          ? { direction: 'row', alignItems: 'center', spacing: 2 }
          : { direction: 'column', spacing: 1 })}
      >
        <Grid item {...(labelLeft ? { xs: 12, md: 5 } : {})}>
          <FormLabel
            className={labelClassName}
            component="label"
            {...(labelLeft && { style: { padding: 0 } })}
          >
            {label}
            {required && '*'}
          </FormLabel>
        </Grid>
        <Grid item {...(labelLeft ? { xs: 12, md: 7 } : {})}>
          <CustomSelectInGrid
            className={clsx(borderless ? classes.borderless : undefined, selectClassName)}
            id="select-outlined"
            variant="outlined"
            multiple={multiple}
            style={{ display: 'flex' }}
            value={value}
            onChange={handleChange}
            disabled={disabled}
            displayEmpty
            IconComponent={ArrowDownComponent}
          >
            {items?.length
              ? itemsWithPlaceholder?.map(item => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))
              : children}
          </CustomSelectInGrid>
        </Grid>
      </Grid>
    )
  }
)

export default SelectInGrid
