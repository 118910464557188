import React, { Suspense } from 'react'
import { Route } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Loading } from '../components/atoms'
import { isLogin } from '../utils/helper'
import RedirectComponent from './RedirectComponent'

const PublicRoute = ({ component: Component, restricted, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isLogin() && restricted ? (
        <Suspense fallback={<Loading />}>
          <RedirectComponent />
        </Suspense>
      ) : (
        <Suspense fallback={<Loading />}>
          <Component {...props} />
        </Suspense>
      )
    }
  />
)

PublicRoute.propTypes = {
  component: PropTypes.object.isRequired,
  restricted: PropTypes.bool,
}

PublicRoute.defaultProps = {
  restricted: false,
}

export default PublicRoute
