import { ApolloClient, ApolloLink, from, HttpLink, InMemoryCache } from '@apollo/client'
// eslint-disable-next-line import/no-extraneous-dependencies
import { onError } from '@apollo/client/link/error'
import * as Sentry from '@sentry/react'
import moment from 'moment'
import config from './config'
import {
  setToken,
  setUid,
  setClient,
  clearUserData,
  getExpiry,
  setExpiry,
  getToken,
  getUid,
  getClient,
  setTimer,
  language,
} from './utils/helper'

const getTimeLeft = () => moment.unix(getExpiry()).diff(moment())
const httpLink = new HttpLink({ uri: `${config.apollo.networkInterface}?locale=${language()}` })

// Setup the header for the request
const middlewareAuthLink = new ApolloLink((operation, forward) => {
  const client = getClient()
  if (!client) {
    clearUserData()
  }

  operation.setContext({
    headers: {
      'Content-type': 'application/json',
      'access-token': getToken(),
      'token-type': 'Bearer',
      uid: getUid(),
      client,
    },
  })

  return forward(operation)
})

// After the backend responds, we take the refreshToken from headers if it exists, and save it in the localStorage.
const afterwareLink = new ApolloLink((operation, forward) =>
  forward(operation).map(response => {
    const context = operation.getContext()
    const {
      response: { headers },
    } = context

    if (headers) {
      const newToken = headers.get('access-token')
      if (newToken?.length) setToken(newToken)
      const newUid = headers.get('uid')
      if (newUid?.length) setUid(newUid)
      const newClient = headers.get('client')
      if (newClient?.length) setClient(newClient)
      const newExpiry = headers.get('expiry')
      if (newExpiry?.length) {
        setExpiry(newExpiry)
      }
    }

    return response
  })
)

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      if (message === 'access denied' && getTimeLeft() - 10000 < 10000) {
        clearUserData()
        setTimer(
          JSON.stringify({
            last_expiry: moment.unix(getExpiry()).toLocaleString(),
            date: moment().toLocaleString(),
            message: 'access denied',
          })
        )
        window.location.replace('/login')
      }
      if (message === 'access denied' && getTimeLeft() - 10000 > 10000) {
        clearUserData()
        setTimer(
          JSON.stringify({
            last_expiry: moment.unix(getExpiry()).toLocaleString(),
            date: moment().toLocaleString(),
            message: 'access denied',
          })
        )
        window.location.replace('/login')
      }
      console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
      Sentry.captureMessage(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    })
  }

  if (networkError) {
    console.error(`[Network error]: ${networkError}`)
    Sentry.captureMessage(`[Network error]: ${networkError}`)
  }
})

const cache = new InMemoryCache()

const client = new ApolloClient({
  link: from([middlewareAuthLink, afterwareLink, errorLink, httpLink]),
  cache,
})

export default client
