import React, { useState } from 'react'
import PropTypes from 'prop-types'
import * as Sentry from '@sentry/browser'
import { getUser, setUser as setStorageUser, removeUser as removeStorageUser } from './helper'

const initialValues = {
  name: 'Max Mustermann',
  first_name: 'Max',
  last_name: 'Mustermann',
  role: '',
}

const getStoreUser = () => {
  const user = { ...JSON?.parse(getUser()) }
  user.name = [user.first_name, user.last_name].join(' ')
  return user
}

const context = {
  user: {
    ...initialValues,
    ...getStoreUser(),
  },
  setUser: () => {},
}

const UserContext = React.createContext(context)

export const UserContextProvider = ({ children }) => {
  const [user, setLocalUser] = useState(context.user)

  const setUser = userData => {
    if (userData) {
      Sentry.setUser({ email: userData?.email })
      setStorageUser(userData)
      setLocalUser(usr => ({
        ...usr,
        ...userData,
        role: userData?.role,
        name: [userData.firstname, userData.lastname].join(' '),
      }))
    }
  }

  const removeUser = () => {
    Sentry.setUser(null)
    removeStorageUser()
    setLocalUser(initialValues)
  }

  return (
    <UserContext.Provider value={{ user, setUser, removeUser }}>{children}</UserContext.Provider>
  )
}
UserContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default UserContext
