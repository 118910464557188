import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import FormItem from '../FormItem'

const InputGroup = React.memo(({ control, elements }) => (
  <Grid container spacing={2}>
    {elements.map(({ sm, md, lg, xl, xs, ...elementProps }) => (
      <Grid item key={elementProps.name} xs={xs || 12} sm={sm} md={md} lg={lg} xl={xl}>
        <FormItem {...elementProps} control={control} />
      </Grid>
    ))}
  </Grid>
))
InputGroup.propTypes = {
  elements: PropTypes.array.isRequired,
  control: PropTypes.func,
}
InputGroup.defaultProps = {
  control: () => {},
}
export default InputGroup
