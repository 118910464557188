import { useState, useEffect, useCallback, useContext } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import client from '../client'
import config from '../config'
import { ME_QUERY } from '../graphql/queries'
import BreadcrumbsContext from './BreadcrumbsContext'
import { getToken, getUid, getClient, setUser, clearUserData } from './helper'
import UserContext from './UserContext'

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimensions
}

export const useLocalStorageJSON = (key, initialState) => {
  const serializedInitialState = JSON.stringify(initialState)
  let storageValue = initialState
  try {
    storageValue = JSON.parse(localStorage.getItem(key) || '') ?? initialState
  } catch {
    localStorage.setItem(key, serializedInitialState)
  }
  const [value, setValue] = useState(storageValue)
  const updatedSetValue = useCallback(
    newValue => {
      const serializedNewValue = JSON.stringify(newValue)
      if (serializedNewValue === serializedInitialState || typeof newValue === 'undefined') {
        localStorage.removeItem(key)
      } else {
        localStorage.setItem(key, serializedNewValue)
      }
      setValue(newValue ?? initialState)
    },
    [initialState, serializedInitialState, key]
  )
  return [value, updatedSetValue]
}

export const useLocalStorage = (key, initialState) => {
  const [value, setValue] = useState(localStorage.getItem(key) ?? initialState)
  const updatedSetValue = useCallback(
    newValue => {
      if (newValue === initialState || typeof newValue === 'undefined') {
        localStorage.removeItem(key)
      } else {
        localStorage.setItem(key, newValue)
      }
      setValue(newValue ?? initialState)
    },
    [initialState, key]
  )
  return [value, updatedSetValue]
}

// runs action(location) on location, i.e. route, change
export const useLocationChange = action => {
  const location = useLocation()
  useEffect(() => {
    action(location)
  }, [action, location])
}

interface FetchExportData {
  loading: boolean
  data?: Blob
  error?: unknown
}

export const useFetchExport: (options: any) => [FetchExportData, (url?: string) => void] = ({
  headers,
}) => {
  const [data, setData] = useState<Blob>()
  const [error, setError] = useState<boolean | unknown>(false)
  const [loading, setLoading] = useState(false)
  const [url, setUrl] = useState<string>()

  useEffect(() => {
    const fetchData = async (u: string) => {
      if (!loading) {
        setError(false)
        setLoading(true)
        try {
          let filename: string | undefined
          const result = await fetch(u, {
            method: 'GET',
            headers: { ...headers, 'access-token': getToken(), uid: getUid(), client: getClient() },
          })
            .then(res => {
              // eslint-disable-next-line prefer-destructuring
              filename = res.headers.get('content-disposition')?.split('"')?.[1]
              return res.blob()
            })
            .then(blob => {
              const downloadUrl = window.URL.createObjectURL(new Blob([blob]))
              const link = document.createElement('a')
              link.href = downloadUrl
              link.setAttribute('download', filename || 'filename')
              link.click()
              link.parentNode?.removeChild(link)
              return blob
            })
            .finally(() => setUrl(undefined))
          setData(result)
        } catch (err) {
          setError(err)
        }
        setLoading(false)
      }
    }

    if (url) fetchData(url)
  }, [url, headers, loading])
  return [{ data, loading, error }, setUrl]
}

export const useLogout = () => {
  const history = useHistory()
  const logout = () => {
    fetch(`${config.apollo.baseUrl}/auth/sign_out`, {
      method: 'DELETE',
      headers: {
        'access-token': getToken(),
        uid: getUid(),
        client: getClient(),
      },
    })
      .then(() => {
        client.resetStore()
        client.cache.reset()
        clearUserData(client)
        history.push('/logout')
      })
      .catch(() => {
        clearUserData(client)
        history.push('/logout')
      })
  }

  return logout
}

export const useMe = () => {
  const methods = useQuery(ME_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: data => {
      setUser(data.me)
    },
    onError: err => console.error('err', err),
  })
  return methods
}

export const useBreadcrumbs = () => {
  const context = useContext(BreadcrumbsContext)
  return context
}

export const useUser = () => {
  const context = useContext(UserContext)
  return context
}
