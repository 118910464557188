import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { Input } from '../../atoms'

const useStyles = makeStyles(() => ({
  whiteSpace: { whiteSpace: 'pre-line' },
  spacingTop: {
    marginTop: 20,
  },
  rightTextFont: {
    fontSize: '0.75rem',
    fontWeight: 400,
    lineHeight: '24px',
  },
  lineHeight: {
    lineHeight: '24px',
  },
}))

const ListItem = React.forwardRef(({ onChange, value, type, label, disabled, helperText }, ref) => {
  const classes = useStyles()
  const selectType = () => {
    let ret = (
      <>
        <Typography
          variant="caption"
          color="textPrimary"
          className={clsx(classes.whiteSpace, classes.rightTextFont)}
        >
          {value}
        </Typography>
      </>
    )
    if (type === 'inputListItem') {
      ret = (
        <Input
          ref={ref}
          value={value}
          fullWidth
          type={type}
          disabled={disabled}
          helperText={helperText}
        />
      )
    }
    if (type === 'textfieldListItem') {
      ret = (
        <Input
          ref={ref}
          value={value}
          onChange={onChange}
          multiline
          rows={5}
          fullWidth
          type={type}
          disabled={disabled}
          helperText={helperText}
        />
      )
    }
    if (type === 'addressListItem') {
      const { streetCustomer, zipCustomer, cityCustomer } = value
      ret = (
        <>
          <Grid item xs={12}>
            <Typography
              variant="caption"
              color="textPrimary"
              className={clsx(classes.whiteSpace, classes.rightTextFont)}
            >
              {streetCustomer}
              <br />
              {`${zipCustomer} ${cityCustomer}`}
            </Typography>
          </Grid>
        </>
      )
    }
    if (type === 'companyBlock') {
      ret = (
        <>
          <Grid container item xs={12}>
            {value.map(v => (
              <Grid item xs={12}>
                <Typography
                  variant="caption"
                  color="textPrimary"
                  className={clsx(classes.whiteSpace, classes.rightTextFont)}
                >
                  {v}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </>
      )
    }
    if (type === 'listingListItem') {
      ret = (
        <>
          <Typography
            variant="caption"
            color="textPrimary"
            className={clsx(classes.whiteSpace, classes.rightTextFont)}
          >
            {value.join(', ')}
          </Typography>
        </>
      )
    }
    return ret
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={4} {...(type === 'textfieldListItem' && { className: classes.spacingTop })}>
        <Typography variant="caption" color="textPrimary" className={classes.lineHeight}>
          {label}
        </Typography>
      </Grid>
      <Grid item xs={8} {...(type === 'textfieldListItem' && { className: classes.spacingTop })}>
        {selectType()}
      </Grid>
    </Grid>
  )
})

ListItem.propTypes = {
  disabled: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.oneOf([
    'defaultListItem',
    'textfieldListItem',
    'addressListItem',
    'listingListItem',
  ]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]).isRequired,
}
ListItem.defaultProps = {
  disabled: false,
  helperText: undefined,
  label: PropTypes.string,
  type: null,
}

export default ListItem
