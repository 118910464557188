import React from 'react'
import PropTypes from 'prop-types'
import { Select as MSelect } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const CustomSelect = ({ maxWidth, minWidth, ...props }) => {
  const Select = withStyles(() => ({
    select: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      maxWidth,
      minWidth,
      padding: '15px 10px',
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
  }))(MSelect)

  return <Select {...props} />
}

CustomSelect.propTypes = {
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

CustomSelect.defaultProps = {
  maxWidth: 180,
  minWidth: 100,
}

export default CustomSelect
