import { css, createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle(
  props => css`
    body {
      margin: 0;
      font-weight: 300;
      color: ${props.theme.colorBlack};
      background-color: #efefef;
    }
    #root {
      background-color: white;
      max-width: 120rem;
      width: 100%;
      margin: 0 auto;
    }
    p {
      margin: 0;
    }

    .material-icons {
      font-family: 'Material Icons';
      font-weight: normal;
      font-style: normal;
      font-size: 24px; /* Preferred icon size */
      display: inline-block;
      line-height: 1;
      text-transform: none;
      letter-spacing: normal;
      word-wrap: normal;
      white-space: nowrap;
      direction: ltr;

      /* Support for all WebKit browsers. */
      -webkit-font-smoothing: antialiased;
      /* Support for Safari and Chrome. */
      text-rendering: optimizeLegibility;

      /* Support for Firefox. */
      -moz-osx-font-smoothing: grayscale;

      /* Support for IE. */
      font-feature-settings: 'liga';
    }
  `
)

export default GlobalStyle
