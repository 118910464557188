import React, { useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Grid, FormHelperText, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Input } from '../../atoms'

const useStyles = makeStyles(theme => ({
  title: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },

  input: {
    padding: '5px',
    textAlign: 'center',
    color: theme.palette.grey[300],
  },
  rightCell: {
    fontSize: '12px',
    height: '25px',
    borderRadius: 0,
    borderColor: 'red',
    margin: 5,
  },
  bold: { fontWeight: 600 },
  label: {
    padding: 0,
  },
  helperText: {
    fontSize: '12px',
  },
}))

const Segmentation = ({ index, defaultValues }) => {
  const { t } = useTranslation('segmentation')
  const classes = useStyles()
  const {
    control,
    trigger,
    formState: { errors },
  } = useFormContext()

  const { psm, biostimulants, liquidFertilizer, beize, otherType } = defaultValues || {}
  const handleChange = (value, onChange) => {
    onChange(value)
    trigger(`positions[${index}]`)
  }
  useEffect(() => {
    if (defaultValues) {
      trigger(`positions[${index}]`)
    }
  }, [trigger, defaultValues, index])
  return (
    <Grid container direction="row" spacing={0} justifyContent="center" alignItems="center">
      <Grid item xs={7}>
        <Typography color="textSecondary">{t('psm')}</Typography>
      </Grid>
      <Grid item xs={5}>
        <Controller
          name={`positions[${index}].psm`}
          control={control}
          defaultValue={psm}
          render={({ field: { onChange, ...field } }) => (
            <Input
              {...field}
              type="number"
              onChange={value => handleChange(value, onChange)}
              variant="outlined"
              small
              labelClassName={classes.label}
              InputProps={{
                classes: {
                  root: classes.rightCell,
                  input: classes.input,
                },
                endAdornment: '%',
              }}
              decimalScale={0}
              allowNegative={false}
              error={errors?.positions?.[index]}
            />
          )}
        />
      </Grid>
      <Grid item xs={7}>
        <Typography color="textSecondary">{t('biostimulants')}</Typography>
      </Grid>
      <Grid item xs={5}>
        <Controller
          name={`positions[${index}].biostimulants`}
          control={control}
          defaultValue={biostimulants}
          render={({ field: { onChange, ...field } }) => (
            <Input
              {...field}
              type="number"
              onChange={value => handleChange(value, onChange)}
              variant="outlined"
              small
              labelClassName={classes.label}
              InputProps={{
                classes: {
                  root: classes.rightCell,
                  input: classes.input,
                },
                endAdornment: '%',
              }}
              decimalScale={0}
              allowNegative={false}
              error={errors?.positions?.[index]}
            />
          )}
        />
      </Grid>
      <Grid item xs={7}>
        <Typography color="textSecondary">{t('liquidFertilizer')}</Typography>
      </Grid>
      <Grid item xs={5}>
        <Controller
          name={`positions[${index}].liquidFertilizer`}
          control={control}
          defaultValue={liquidFertilizer}
          render={({ field: { onChange, ...field } }) => (
            <Input
              {...field}
              type="number"
              onChange={value => handleChange(value, onChange)}
              variant="outlined"
              small
              labelClassName={classes.label}
              InputProps={{
                classes: {
                  root: classes.rightCell,
                  input: classes.input,
                },
                endAdornment: '%',
              }}
              decimalScale={0}
              allowNegative={false}
              error={errors?.positions?.[index]}
            />
          )}
        />
      </Grid>
      <Grid item xs={7}>
        <Typography color="textSecondary">{t('beize')}</Typography>
      </Grid>
      <Grid item xs={5}>
        <Controller
          name={`positions[${index}].beize`}
          control={control}
          defaultValue={beize}
          render={({ field: { onChange, ...field } }) => (
            <Input
              {...field}
              type="number"
              onChange={value => handleChange(value, onChange)}
              variant="outlined"
              small
              labelClassName={classes.label}
              InputProps={{
                classes: {
                  root: classes.rightCell,
                  input: classes.input,
                },
                endAdornment: '%',
              }}
              decimalScale={0}
              allowNegative={false}
              error={errors?.positions?.[index]}
            />
          )}
        />
      </Grid>
      <Grid item xs={7}>
        <Typography color="textSecondary">{t('otherType')}</Typography>
      </Grid>
      <Grid item xs={5}>
        <Controller
          name={`positions[${index}].otherType`}
          control={control}
          defaultValue={otherType}
          render={({ field: { onChange, ...field } }) => (
            <Input
              {...field}
              type="number"
              onChange={value => handleChange(value, onChange)}
              variant="outlined"
              small
              labelClassName={classes.label}
              InputProps={{
                classes: {
                  root: classes.rightCell,
                  input: classes.input,
                },
                endAdornment: '%',
              }}
              decimalScale={0}
              allowNegative={false}
              error={errors?.positions?.[index]}
            />
          )}
        />
      </Grid>
      {errors?.positions?.[index] && (
        <FormHelperText className={classes.helperText} error>
          {t(errors?.positions?.[index]?.message)}
        </FormHelperText>
      )}
    </Grid>
  )
}

Segmentation.propTypes = {
  index: PropTypes.number.isRequired,
  defaultValues: PropTypes.object,
}
Segmentation.defaultProps = {
  defaultValues: null,
}

export default Segmentation
