const colors = {
  black: 'black',
  white: '#fff',
  primaryColor: '#c9db89',
  primaryDarker: '#b8cf63',
  secondaryColor: '#0079ba',
  blue: '#677c98',
  green: '#4b5b71',
  textGrey: '#3d6372',
  systemBlue: '#6ea2d3',
  gSystemOrange: '#E5734B',
  pickupSystemBlue: '#CBDBEE',
  dualGreen: '#C9DB89',
  grey: '#DFDFDF',
  backgroundGrey: '#f5f5f5',
  borderGrey: 'rgba(0, 0, 0, 0.23)',
  brown: '#3F2B1E',
  pamiraGreen: '#70BC7E',
  greyDefault: '#BFBFBF',
  erde: '#B5C35D',
  beize: '#DCC35C',
  pre: '#C2A282',
  yellow: '#f2c94c',
  red: '#f5222e',
  linkHover: '#00476e',
}
export default colors
