import React, { useState } from 'react'
import PropTypes from 'prop-types'

export const defaultContextValue = {
  breadcrumbs: [],
  setBreadcrumbs: () => {},
}

const BreadcrumbsContext = React.createContext(defaultContextValue)

export const BreadcrumbsProvider = ({ children }) => {
  const [breadcrumbs, setBreadcrumbs] = useState(defaultContextValue.breadcrumbs)
  const context = {
    breadcrumbs,
    setBreadcrumbs,
  }

  return <BreadcrumbsContext.Provider value={context}>{children}</BreadcrumbsContext.Provider>
}
BreadcrumbsProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default BreadcrumbsContext
