import React from 'react'
import PropTypes from 'prop-types'
import LoggedInLayout from './LoggedInLayout'
import LoggedOutLayout from './LoggedOutLayout'

const MainLayout = ({ children, logged }) =>
  logged ? (
    <LoggedInLayout>{children}</LoggedInLayout>
  ) : (
    <LoggedOutLayout>{children}</LoggedOutLayout>
  )

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
  logged: PropTypes.bool.isRequired,
}

export default MainLayout
