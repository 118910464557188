import React from 'react'
import PropTypes from 'prop-types'
import { Divider, MenuItem } from '../../atoms'

const SideBar = ({ items }) => (
  <div>
    {items?.map(item =>
      item?.title ? <MenuItem item={item} key={item.title} /> : <Divider key={item} />
    )}
  </div>
)

SideBar.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string,
      title: PropTypes.string,
      subItems: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          to: PropTypes.string,
        })
      ),
    })
  ).isRequired,
}

export default SideBar
