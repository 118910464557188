import React, { useEffect, useState, forwardRef } from 'react'
import PropTypes from 'prop-types'
import { Checkbox, FormControlLabel, FormHelperText } from '@material-ui/core'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'

const CheckBox = forwardRef(
  ({ className, checked: propChecked, onChange, name, label, helperText, error }, ref) => {
    const [checked, setChecked] = useState(propChecked)
    useEffect(() => {
      setChecked(propChecked)
    }, [propChecked])

    const handleChange = e => {
      setChecked(e.target.checked)
      if (onChange) onChange(e.target.checked, e.target.name)
    }

    return (
      <>
        <FormControlLabel
          className={className}
          control={
            <Checkbox
              ref={ref}
              checked={checked}
              color="secondary"
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              onChange={handleChange}
              name={name}
            />
          }
          label={label}
        />
        <FormHelperText error={error}>{helperText}</FormHelperText>
      </>
    )
  }
)

CheckBox.propTypes = {
  checked: PropTypes.bool,
  error: PropTypes.bool,
  onChange: PropTypes.func,
  name: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  helperText: PropTypes.string,
}
CheckBox.defaultProps = {
  checked: false,
  error: false,
  onChange: null,
  name: 'CheckBox',
  label: '',
  className: null,
  helperText: undefined,
}

export default CheckBox
