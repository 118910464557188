import React from 'react'
import PropTypes from 'prop-types'
import { Tab as MTab } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '0 0',
    minWidth: '0',
    minHeight: '0',
    textTransform: 'none',
    color: theme.palette.textGrey,
    fontWeight: 600,
    marginRight: '29px',
    '& .MuiTabs-scroller .MuiTabs-flexContainer .': { border: '2px solid blue' },
    '&:focus': {
      opacity: 1,
    },
    '&.Mui-selected': {
      fontWeight: 700,
    },
  },
  card: {
    border: `1px solid ${theme.palette.grey[100]}`,
    borderBottom: 'none',
    marginRight: 5,
    padding: '10px 20px',
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
    background: theme.palette.backgroundGrey,
    '&.Mui-selected': {
      color: theme.palette.secondary.main,
      background: 'white',
    },
  },
}))

const Tab = ({ variant, ...props }) => {
  const classes = useStyles()
  return <MTab {...props} className={clsx(classes.root, classes[variant])} disableRipple />
}
Tab.propTypes = {
  variant: PropTypes.string.isRequired,
}

export default Tab
