import styled, { css } from 'styled-components'

const RoundImage = styled.img(
  props => css`
  width: ${props.width || '100px'};
  height: $props.height || '100px'};
  border-radius: 50%;
`
)

export default RoundImage
