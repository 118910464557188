import React from 'react'
import PropTypes from 'prop-types'
import { Tabs as MTabs } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    minHeight: 'fit-content',
    '& .MuiTabs-scrollButtonsDesktop': { color: theme.palette.primary.main },
  },
  default: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
  },
}))

const Tabs = ({ customVariant, ...props }) => {
  const classes = useStyles()
  return (
    <MTabs
      TabIndicatorProps={{ className: classes[customVariant] }}
      className={clsx(classes.root)}
      {...props}
    />
  )
}
Tabs.propTypes = {
  customVariant: PropTypes.string.isRequired,
}

export default Tabs
