import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  RadioGroup,
  Radio,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  colorSecondary: {
    color: `${theme.palette.textGrey} !important`,
  },
}))

const RadioButtons = React.forwardRef(
  ({ items, onChange, value, direction, label, name, withinForm, error }, ref) => {
    const [val, setVal] = useState(value)
    const classes = useStyles()

    useEffect(() => {
      setVal(value)
    }, [value])

    const handleChange = event => {
      const {
        target: { value: newValue },
      } = event
      setVal(newValue)
      if (withinForm) {
        onChange?.(event)
      } else {
        onChange?.(newValue)
      }
    }
    return (
      <FormControl component="fieldset">
        <FormLabel component="legend">{label}</FormLabel>
        <RadioGroup
          innerRef={ref}
          aria-label={label}
          name={name}
          value={val}
          onChange={handleChange}
          row={direction === 'horizontal'}
        >
          {items?.map(item => (
            <FormControlLabel
              value={item.value}
              control={<Radio classes={classes} />}
              label={item.label}
              key={item.value}
            />
          ))}
          {error && <FormHelperText>{error}</FormHelperText>}
        </RadioGroup>
      </FormControl>
    )
  }
)
RadioButtons.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  value: PropTypes.any,
  name: PropTypes.string,
  withinForm: PropTypes.bool,
  label: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func,
  direction: PropTypes.oneOf(['horizontal', 'vertical']),
}
RadioButtons.defaultProps = {
  value: null,
  onChange: null,
  withinForm: null,
  name: 'radiogroup',
  label: null,
  error: null,
  direction: 'horizontal',
}

export default RadioButtons
