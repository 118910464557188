import styled, { css } from 'styled-components'

const defaultProps = {
  theme: { palette: { text: { primary: null }, systemBlue: null, pickupSystemBlue: null } },
}

const StyledA = styled.a(props => {
  const {
    theme: {
      palette: {
        text: { primary },
        systemBlue,
        pickupSystemBlue,
      },
    },
  } = props || defaultProps

  return css`
    display: inline-flex;
    height: 55px;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    text-decoration: none;
    color: ${primary};
    font-size: 1.0625rem;
    font-weight: 700;
    border-bottom: 3px solid transparent;
    &:hover,
    .selected {
      color: ${systemBlue};
      background-color: ${pickupSystemBlue};
      border-bottom-color: ${systemBlue};
    }
    cursor: pointer;
    user-select: none;
  `
})

export default StyledA
