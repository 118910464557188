import React, { useContext } from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Typography, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import LocaleContext from '../../../utils/LocaleContext'
import { Input } from '../../atoms'
import Segmentation from './Segmentation'

const useStyles = makeStyles(theme => ({
  colorBlack: {
    '&.Mui-disabled,.MuiInputBase-input': {
      color: `${theme.palette.text.secondary} !important`,
    },
    '& > .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: `${theme.palette.grey[500]}`,
    },
  },
  maxWidth100: {
    maxWidth: '100%',
    paddingBottom: '20px',
  },
  spacingTop: {
    marginTop: '18px',
  },
  smallSpacingTop: {
    marginTop: '10px',
  },
}))

const FormItem = ({ row, index, defaultValues }) => {
  const { language } = useContext(LocaleContext)
  const { t } = useTranslation('common')
  const classes = useStyles()
  const { control, setValue, watch } = useFormContext()
  const amount = watch(`positions[${index}].amount`)

  if (defaultValues?.rowId) {
    setValue(`positions[${index}].id`, defaultValues.rowId, { shouldDirty: true })
  }
  const { title, titleEn, position, form } = row
  if (form) {
    setValue(`positions[${index}].position`, position, { shouldDirty: true })
    setValue(`positions[${index}].title`, title, {
      shouldDirty: true,
    })
  }
  return amount ? (
    <Grid container spacing={2} className={classes.maxWidth100}>
      <Grid item sm={12} md={6} lg={1} className={classes.spacingTop}>
        <Typography color="textSecondary">{position}</Typography>
      </Grid>
      <Grid item sm={12} md={6} lg={4} className={classes.spacingTop}>
        <Typography color="textSecondary">{language?.name === 'de' ? title : titleEn}</Typography>
      </Grid>
      <Grid item sm={12} md={6} lg={3}>
        {form && (
          <Controller
            name={`positions[${index}].amount`}
            defaultValue={defaultValues?.amount}
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                className={classes.colorBlack}
                type="number"
                placeholder={t('amountInKilo')}
                small
                disabled
              />
            )}
          />
        )}
      </Grid>
      <Grid item sm={12} md={6} lg={4} className={classes.smallSpacingTop}>
        {form && row?.withSegmentation && (
          <Segmentation index={index} defaultValues={defaultValues} disabled />
        )}
      </Grid>
    </Grid>
  ) : null
}

FormItem.propTypes = {
  row: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  defaultValues: PropTypes.object,
}
FormItem.defaultProps = {
  defaultValues: null,
}

export default FormItem
