import React, { Suspense } from 'react'
import { useLocation } from 'react-router'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Loading, Footer } from '../components/atoms'
import { ContactBox, SideBar } from '../wrappers'
import Header from './Header'

const useStyles = makeStyles(theme => ({
  content: {
    minHeight: '80vh',
    width: '100%',
    maxWidth: theme.containerWidth,
    margin: 'auto',
    padding: theme.spacing(1),
  },
  children: {
    paddingTop: theme.spacing(2),
    height: '100%',
  },
  paddingTop: {
    paddingTop: theme.spacing(2),
  },
}))

const ROW = 'row'

const LoggedInLayout = ({ children }) => {
  const classes = useStyles()
  // CK - Note: removed until we need it
  // const breadcrumbs = []
  const location = useLocation()
  const bottomContactBoxParams = ['all-reports']
  const bottomContactBoxPaths = ['invoices', 'all-reports']

  const getOrientation = () => {
    let returnValue = 'column'
    if (bottomContactBoxPaths.find(a => location.pathname.includes(a))) returnValue = ROW
    if (bottomContactBoxParams.find(a => location.search.includes(a))) returnValue = ROW
    return returnValue
  }

  const orientation = getOrientation()

  return (
    <div>
      <Suspense fallback={<Loading />}>
        <Header loggedIn />
      </Suspense>
      <div className={classes.content}>
        <Grid container spacing={4}>
          <Grid item xs={false} lg={2} />
          <Grid item xs={false} lg={10}>
            <Suspense fallback={<Loading />}>
              {/* CK - Note: removed until we need it */}
              {/* <Breadcrumbs items={breadcrumbs} /> */}
            </Suspense>
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={false} lg={2}>
            <div className={classes.paddingTop}>
              <Suspense fallback={<Loading />}>
                <SideBar />
              </Suspense>
            </div>
          </Grid>
          <Grid item xs={12} lg={orientation === ROW ? 10 : 7}>
            <Suspense fallback={<Loading />}>
              <div className={classes.children}>{children}</div>
            </Suspense>
          </Grid>
          <Grid item xs={12} lg={orientation === ROW ? 12 : 3}>
            <div className={classes.paddingTop}>
              <Suspense fallback={<Loading />}>
                <ContactBox orientation={orientation} />
              </Suspense>
            </div>
          </Grid>
        </Grid>
      </div>
      <Suspense fallback={<Loading />}>
        <Footer />
      </Suspense>
    </div>
  )
}
LoggedInLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
}

export default LoggedInLayout
