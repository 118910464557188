import { useContext, useMemo } from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Typography, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import LocaleContext from '../../../utils/LocaleContext'
import { Input } from '../../atoms'
import Segmentation from './Segmentation'

const useStyles = makeStyles(() => ({
  spacingBottom: {
    paddingBottom: '20px',
    maxWidth: '100%',
  },
  smallSpacingTop: {
    marginTop: '10px',
  },
  spacingTop: {
    marginTop: '18px',
  },
}))

const FormItem = ({ row, index, data }) => {
  const { t } = useTranslation('common')
  const classes = useStyles()
  const { language } = useContext(LocaleContext)
  const { control, trigger, setValue } = useFormContext()
  const defaultValues = useMemo(() => {
    const {
      amount,
      beize,
      biostimulants,
      id: rowId,
      liquidFertilizer,
      noticeType,
      otherType,
      position,
      psm,
      title,
    } = data?.find(pc => pc.position === row.position) || {}

    return rowId
      ? {
          amount,
          beize,
          biostimulants,
          rowId,
          liquidFertilizer,
          noticeType,
          otherType,
          position,
          psm,
          title,
        }
      : undefined
  }, [data, row])

  if (defaultValues?.rowId) {
    setValue(`positions[${index}].id`, defaultValues.rowId, { shouldDirty: true })
  }

  const { title, titleEn, position, form } = row
  if (form) {
    setValue(`positions[${index}].position`, position, { shouldDirty: true })
    setValue(`positions[${index}].title`, title, {
      shouldDirty: true,
    })
  }

  const handleChange = (value, onChange, name) => {
    if (value === 0 || !value) {
      setValue(name, undefined)
    } else {
      setValue(name, value)
    }
    onChange(value)
    trigger(`positions[${index}]`)
  }
  return (
    <Grid container spacing={2} className={classes.spacingBottom}>
      <Grid item sm={12} md={6} lg={1} className={classes.spacingTop}>
        <Typography color="textSecondary">{position}</Typography>
      </Grid>
      <Grid item sm={12} md={6} lg={4} className={classes.spacingTop}>
        <Typography color="textSecondary">{language?.name === 'de' ? title : titleEn}</Typography>
      </Grid>
      <Grid item sm={12} md={6} lg={3}>
        {form && (
          <Controller
            name={`positions[${index}].amount`}
            defaultValue={defaultValues?.amount}
            control={control}
            render={({ field: { onChange, ...field } }) => (
              <Input
                {...field}
                type="number"
                onChange={value => {
                  handleChange(value, onChange, `positions[${index}].amount`)
                }}
                placeholder={t('amountInKilo')}
                small
                allowNegative={false}
                decimalScale={0}
              />
            )}
          />
        )}
      </Grid>
      <Grid item sm={12} md={6} lg={4} className={classes.smallSpacingTop}>
        {form && row?.withSegmentation && (
          <Segmentation index={index} defaultValues={defaultValues} />
        )}
      </Grid>
    </Grid>
  )
}

FormItem.propTypes = {
  row: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  data: PropTypes.array,
}
FormItem.defaultProps = {
  data: null,
}

export default FormItem
