import React from 'react'
import PropTypes from 'prop-types'
import { ErrorMessage as HookFormErrorMessage } from '@hookform/error-message'
import { Typography } from '@material-ui/core'
import styled, { css } from 'styled-components'

const StyledError = styled(Typography)(
  props => css`
    color: ${props.theme.palette.error.main};
  `
)
const ErrorMessage = ({ errors, name }) => (
  <HookFormErrorMessage errors={errors} name={name} as={<StyledError />} />
)
ErrorMessage.propTypes = {
  errors: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
}

export default ErrorMessage
