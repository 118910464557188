import React from 'react'
import PropTypes from 'prop-types'
import { CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    paddingTop: theme.spacing(4),
  },
}))

const Loading = ({ children }) => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <CircularProgress color="secondary" />
      <div className={classes.text}>{children}</div>
    </div>
  )
}
Loading.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}
Loading.defaultProps = {
  children: null,
}

export default Loading
