import React, { useState, useEffect } from 'react'
import NumberFormat from 'react-number-format'
import PropTypes from 'prop-types'
import { FormLabel, Grid, TextField } from '@material-ui/core'

const CustomNumber = ({ inputRef, onChange: onChangeValue, name, ...other }) => (
  <NumberFormat
    {...other}
    getInputRef={inputRef}
    onValueChange={({ floatValue }) => {
      onChangeValue(floatValue === undefined ? '' : floatValue)
    }}
    thousandSeparator="."
    decimalSeparator=","
  />
)

CustomNumber.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

const Input = React.forwardRef(
  (
    {
      disabled,
      fullWidth,
      label,
      multiline,
      onChange,
      rows,
      showPassword,
      small,
      type,
      className,
      labelClassName,
      value,
      defaultValue,
      name,
      error,
      autoFocus,
      helperText,
      required,
      placeholder,
      noLabel,
      labelLeft,
      InputProps,
      decimalScale,
      allowNegative,
    },
    ref
  ) => {
    const [visible, setVisible] = useState(false)

    useEffect(() => {
      setVisible(showPassword)
    }, [showPassword])

    const defaultFieldProps = {
      variant: 'outlined',
      disabled,
      autoFocus,
      name,
      onChange,
      size: small ? 'small' : 'medium',
      value,
      defaultValue,
      fullWidth,
      multiline,
      rows,
      className,
      error,
      helperText,
      placeholder,
    }

    const numberFieldProps = {
      InputProps: {
        inputComponent: CustomNumber,
        ...InputProps,
        inputProps: {
          decimalScale,
          allowNegative,
        },
      },
    }

    const moneyFieldProps = {
      InputProps: {
        endAdornment: '€',
        inputComponent: CustomNumber,
        ...InputProps,
        inputProps: {
          decimalScale,
          allowNegative,
        },
      },
    }

    const passwordFieldProps = { type: visible ? 'test' : 'password' }

    let textFieldComponent = <TextField inputRef={ref} {...defaultFieldProps} />

    if (type === 'number') {
      textFieldComponent = <TextField inputRef={ref} {...defaultFieldProps} {...numberFieldProps} />
    }

    if (type === 'money') {
      textFieldComponent = <TextField inputRef={ref} {...defaultFieldProps} {...moneyFieldProps} />
    }

    if (type === 'password') {
      textFieldComponent = (
        <TextField inputRef={ref} {...defaultFieldProps} {...passwordFieldProps} />
      )
    }

    return (
      <Grid
        container
        {...(labelLeft
          ? { direction: 'row', alignItems: 'center', spacing: 2 }
          : { direction: 'column' })}
      >
        {(!noLabel || !label.length) && (
          <Grid item {...(labelLeft ? { xs: 12, md: 5 } : {})}>
            <FormLabel
              className={labelClassName}
              component="label"
              {...(labelLeft && { style: { padding: 0 } })}
            >
              {label}
              {required && '*'}
            </FormLabel>
          </Grid>
        )}
        <Grid item {...(labelLeft ? { xs: 12, md: 7 } : {})}>
          {textFieldComponent}
        </Grid>
      </Grid>
    )
  }
)

Input.propTypes = {
  name: PropTypes.string,
  error: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  multiline: PropTypes.bool,
  onChange: PropTypes.func,
  rows: PropTypes.number,
  showPassword: PropTypes.bool,
  small: PropTypes.bool,
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  type: PropTypes.oneOf(['default', 'money', 'password', 'textfield', 'number']),
  helperText: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  noLabel: PropTypes.bool,
  labelLeft: PropTypes.bool,
  InputProps: PropTypes.object,
  decimalScale: PropTypes.number,
  allowNegative: PropTypes.bool,
}
Input.defaultProps = {
  value: undefined,
  defaultValue: undefined,
  error: null,
  disabled: false,
  fullWidth: false,
  label: '',
  multiline: false,
  onChange: () => {},
  rows: null,
  showPassword: false,
  small: false,
  autoFocus: false,
  type: 'default',
  className: null,
  labelClassName: null,
  name: null,
  helperText: undefined,
  placeholder: undefined,
  required: false,
  noLabel: false,
  labelLeft: false,
  InputProps: null,
  decimalScale: 2,
  allowNegative: true,
}

export default Input
