import React, { useContext } from 'react'
import { useHistory } from 'react-router'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import localeContext from '../../../utils/LocaleContext'
import Select from '../Select'

const LanguagePicker = ({ selectClass, labelClass }) => {
  const { language, setLanguage, availableLanguages } = useContext(localeContext)
  const history = useHistory()
  const setStoredLanguage = lang => {
    setLanguage(lang)
    const parsed = queryString.parse(history.location.search)
    parsed.locale = lang
    const search = queryString.stringify(parsed)

    history.push({ pathname: history.location.pathname, search })
    window.location.reload()
  }
  return (
    <Select
      borderless
      minWidth={0}
      items={availableLanguages}
      value={language?.name}
      onChange={setStoredLanguage}
      selectClass={selectClass}
      labelClass={labelClass}
    />
  )
}
LanguagePicker.propTypes = {
  selectClass: PropTypes.string,
  labelClass: PropTypes.string,
}
LanguagePicker.defaultProps = {
  selectClass: undefined,
  labelClass: undefined,
}

export default LanguagePicker
