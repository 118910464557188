import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
  email: Yup.string().email('auth:invalidEmail').required('auth:required'),
  requestedCustomers: Yup.array().of(
    Yup.object().shape({
      agreementNo: Yup.string(),
      companyName: Yup.string().required('auth:required'),
    })
  ),
  password: Yup.string().required('auth:required'),
  passwordConfirmation: Yup.string().required('auth:required'),
})
export default validationSchema
