import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Typography, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Input } from '../../atoms'
import FormItem from './DisplayFormItem'

const useStyles = makeStyles(theme => ({
  bold: { fontWeight: 600 },
  label: {
    padding: 0,
  },
  colorBlack: {
    '&.Mui-disabled,.MuiInputBase-input': {
      color: `${theme.palette.text.secondary} !important`,
    },
    '& > .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: `${theme.palette.grey[500]}`,
    },
  },
  maxWidth100: {
    maxWidth: '100%',
  },
}))

const DisplayFormGroup = ({ fields, sumTitle, startIndex }) => {
  const { t } = useTranslation('common')
  const classes = useStyles()
  const { watch } = useFormContext()

  const renderSum = () => {
    const sum = fields?.reduce((a, field, index) => {
      let returnVal = 0
      if (field.form) {
        returnVal = watch(`positions[${index + startIndex}].amount`)
      }
      returnVal = parseFloat(returnVal)
      returnVal = Number.isNaN(returnVal) ? 0 : returnVal
      return a + returnVal
    }, 0)

    return (
      sum !== 0 && (
        <Grid
          container
          alignItems="center"
          spacing={2}
          direction="row"
          className={classes.maxWidth100}
        >
          <Grid item sm={12} md={6} lg={4}>
            <Typography color="textSecondary" className={classes.bold}>
              {sumTitle}
            </Typography>
          </Grid>
          <Grid item sm={12} md={6} lg={1} />

          <Grid item sm={12} md={6} lg={3}>
            <div className={classes.colorBlack}>
              <Input
                name="sum"
                value={sum}
                type="number"
                placeholder={t('amountInKilo')}
                small
                labelClassName={classes.label}
                disabled
                className={classes.colorBlack}
                decimalScale={0}
              />
            </div>
          </Grid>
        </Grid>
      )
    )
  }
  return (
    <>
      {fields?.map((field, index) => (
        <FormItem key={field.position} row={field} index={index + startIndex} />
      ))}
      {sumTitle && renderSum()}
    </>
  )
}

DisplayFormGroup.propTypes = {
  fields: PropTypes.array.isRequired,
  startIndex: PropTypes.number.isRequired,
  sumTitle: PropTypes.string,
}
DisplayFormGroup.defaultProps = {
  sumTitle: null,
}

export default DisplayFormGroup
