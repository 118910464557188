import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import { useLocationChange } from '../../../utils/hooks'
import { AppBar, Tab, Tabs, TabPanel } from './components'

const a11yProps = index => ({
  id: `scrollable-auto-tab-${index}`,
  'aria-controls': `scrollable-auto-tabpanel-${index}`,
})
const TabbedContent = React.memo(({ tabs, scrollable, defaultActive, variant, isUrlParameter }) => {
  const [value, setValue] = useState(defaultActive || tabs?.[0]?.key || 0)
  const history = useHistory()
  const { pathname } = useLocation()
  const handleChange = (event, newValue) => {
    if (isUrlParameter) {
      history.push({
        pathname,
        search: queryString.stringify({ tab: newValue }),
      })
    }
    setValue(newValue)
  }

  const routeToTab = location => {
    if (isUrlParameter && location?.search) {
      const { tab } = queryString.parse(location.search)
      if (value !== tab) {
        setValue(tab)
      }
    }
  }

  useLocationChange(routeToTab)

  return (
    <>
      <AppBar>
        <Tabs
          value={value}
          variant={scrollable ? 'scrollable' : 'standard'}
          customVariant={variant}
          color="default"
          indicatorColor={variant === 'card' ? 'transparent' : undefined}
          onChange={handleChange}
        >
          {tabs?.map((tab, i) => (
            <Tab
              variant={variant}
              key={tab.key}
              value={tab.key || i}
              label={tab.label}
              {...a11yProps(i)}
            />
          ))}
        </Tabs>
      </AppBar>
      {tabs?.map((tab, i) => (
        <TabPanel key={tab.key || i} value={value} index={tab.key || i}>
          {tab.content}
        </TabPanel>
      ))}
    </>
  )
})

TabbedContent.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      label: PropTypes.string,
      content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    })
  ),
  scrollable: PropTypes.bool,
  isUrlParameter: PropTypes.bool,
  defaultActive: PropTypes.any,
  variant: PropTypes.oneOf(['default', 'card']),
}
TabbedContent.defaultProps = {
  tabs: null,
  scrollable: true,
  isUrlParameter: false,
  defaultActive: null,
  variant: 'default',
}
export default TabbedContent
