import React, { useCallback, useState, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { FormLabel, Typography, CircularProgress, FormHelperText } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { DirectUpload, Blob } from '@rails/activestorage'
import { Checked, Delete, Plus, Upload } from 'assets/icons'
import clsx from 'clsx'
import OverflowTip from 'components/atoms/OverflowTip'
import config from 'config'
import { IFile } from 'utils/types'

const DIRECT_UPLOAD_URL = `${config.apollo.baseUrl}/direct_uploads`

const useStyles = makeStyles(theme => ({
  container: {
    color: theme.palette.secondary.main,
    cursor: 'pointer',
    maxWidth: '100%',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  newFile: {
    color: theme.palette.secondary.main,
  },
  text: {
    paddingLeft: theme.spacing(2),
  },
  typography: {
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: 1.5,
  },
  disabled: {
    color: theme.palette.grey[100],
    cursor: 'not-allowed',
  },
  noFileText: {
    color: theme.palette.error.main,
    fontSize: '0.625rem',
  },
}))

interface Props {
  accept?: string
  label?: string
  required?: boolean
  maxFiles?: number
  maxSize?: number
  multiple?: boolean
  disabled?: boolean
  noFileText?: string
  onFilesChanged?(files: IFile[]): void
  onFileDeleted?(file: IFile, name?: string): void
  name?: string
  value?: any[]
  labelClassName?: string
  error?: boolean
  helperText?: string
}

const Uploader = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      accept,
      label,
      required,
      maxFiles = 0,
      maxSize = 24 * 1024 * 1024, // 24 MB
      multiple,
      disabled,
      noFileText,
      onFilesChanged,
      onFileDeleted,
      name,
      value,
      labelClassName,
      error,
      helperText,
    },
    ref
  ) => {
    const [errorMessage, setErrorMessage] = useState<boolean | string | undefined | null>()
    const classes = useStyles()
    const { t } = useTranslation('common')
    const [hovering, setHovering] = useState<string | null>(null)
    const [uploading, setUploading] = useState(false)

    const [files, setFiles] = useState<IFile[]>([])
    useEffect(() => {
      if (value?.length !== files.length) setFiles(value || [])
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, setFiles])

    useEffect(() => {
      if (files.length) onFilesChanged?.(files)
    }, [files, onFilesChanged])

    const deleteFile = (file: IFile, e: React.MouseEvent<any>) => {
      e.stopPropagation()
      setHovering(null)
      setFiles(files.filter(f => f.filename !== file.filename))
      onFileDeleted?.(file, name)
    }

    const browserFileTypes = [
      'image/gif',
      'image/png',
      'image/jpeg',
      'text/plain',
      'application/pdf',
    ]

    const getPreviewFromUrl = async (url?: string) => {
      if (!url) return
      if (url.includes('blob:')) window.open(url, '_blank')
      else {
        const blob = await fetch(url).then(r => r.blob())
        window.open(
          browserFileTypes.includes(blob.type) ? URL.createObjectURL(blob) : url,
          '_blank'
        )
      }
    }

    const onDrop = useCallback(
      newFiles => {
        setErrorMessage(false)
        setUploading(true)
        newFiles.forEach((newFile: File, i: number) => {
          const upload = new DirectUpload(newFile, DIRECT_UPLOAD_URL)

          return upload.create((err: Error, newBlob: Blob) => {
            if (err) {
              console.error('err', err)
              return
            }

            setFiles((f?: IFile[]): IFile[] => [
              ...(f || []),
              { ...newBlob, name, attachmentUrl: URL.createObjectURL(newFile) } as IFile,
            ])
            if (newFiles.length - 1 === i) setUploading(false)
          })
        })
      },
      [setFiles, name]
    )

    const getNumberBelowThousand = (val: number, count = 0) => {
      const sizes = ['B', 'KB', 'MB', 'GB']
      if (val < 1024) return { size: sizes[count], num: val }
      return getNumberBelowThousand(val / 1024, count + 1)
    }

    const getMaxFileSize = (val: number) => {
      const { size, num } = getNumberBelowThousand(val)

      return `${num.toFixed(1)} ${size}`
    }

    const onDropRejected = () => {
      setUploading(false)
      setErrorMessage(
        t('reachedMaxFileSize', {
          defaultValue: 'Datei zu groß, maximale Uploadgröße {{size}}',
          size: getMaxFileSize(maxSize),
        }) as string
      )
    }
    const { getRootProps, getInputProps } = useDropzone({
      onDrop,
      accept,
      maxFiles,
      maxSize,
      multiple,
      onDropRejected,
    })
    const getIcon = () => {
      let ret = <Upload />
      if (files.length) ret = <Plus />
      if (uploading) ret = <CircularProgress />
      return ret
    }

    return (
      <div
        ref={ref}
        {...getRootProps()}
        className={clsx(classes.container, disabled && classes.disabled)}
      >
        {!!label && (
          <FormLabel component="label" className={labelClassName}>
            {label}
            {required ? '*' : ''}
          </FormLabel>
        )}
        {maxFiles === 0 || files.length < maxFiles ? (
          <input {...getInputProps()} disabled={disabled || uploading} hidden={uploading} />
        ) : null}
        {files.map(file => (
          <div key={file?.filename} className={clsx(classes.row, classes.newFile)}>
            {/* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */}
            <div
              onMouseOver={() => setHovering(file?.signed_id || null)}
              onMouseOut={() => setHovering(null)}
              className={classes.row}
            >
              {hovering === file?.signed_id ? (
                <Delete onClick={e => deleteFile(file, e)} />
              ) : (
                <Checked />
              )}
            </div>
            <OverflowTip
              color="secondaryColor"
              className={clsx(classes.text, classes.typography)}
              onClick={() => getPreviewFromUrl(file.attachmentUrl)}
            >
              {file?.filename || ''}
            </OverflowTip>
          </div>
        ))}
        {maxFiles === 0 || files.length < maxFiles ? (
          <div className={classes.row}>
            {getIcon()}
            <Typography className={classes.text}>
              {files.length ? t('uploadAdditionalDocument') : t('clickToSelectFile')}
            </Typography>
          </div>
        ) : null}
        {files.length <= 0 && noFileText && (
          <Typography className={classes.noFileText}>{noFileText}</Typography>
        )}
        <FormHelperText error={error}>{helperText}</FormHelperText>
        {errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
      </div>
    )
  }
)

export default Uploader
