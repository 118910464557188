import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Text } from '../../atoms'
import { ContactInfo } from '../../molecules'

const useStyles = makeStyles(theme => ({
  text: {
    paddingTop: theme.spacing(2),
    color: theme.palette.textGrey,
  },
  grid: {
    '& > div': {
      paddingTop: theme.spacing(2),
    },
  },
}))

const ContactBox = ({ contacts, orientation }) => {
  const classes = useStyles()
  const { t } = useTranslation('common')
  return (
    <Grid container direction={orientation} className={classes.grid} spacing={6}>
      <Grid item md={orientation === 'row' ? 4 : 12} sm={12}>
        <Typography variant="h2">{t('youHaveQuestions')}</Typography>
        <Text className={classes.text}>{t('questionsInfoText')}</Text>
      </Grid>
      {contacts?.map(({ name, email, phone, jobTitle, imgSrc, imgAlt, imgOptions }) => (
        <Grid key={name} item md={orientation === 'row' ? 4 : 12} sm={12}>
          <ContactInfo
            key={name}
            name={name}
            email={email}
            phone={phone}
            jobTitle={jobTitle}
            imgSrc={imgSrc}
            imgAlt={imgAlt}
            imgOptions={imgOptions}
          />
        </Grid>
      ))}
    </Grid>
  )
}
ContactBox.propTypes = {
  contacts: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      phone: PropTypes.string.isRequired,
      jobTitle: PropTypes.string.isRequired,
      imgSrc: PropTypes.string.isRequired,
      imgAlt: PropTypes.string.isRequired,
      imgOptions: PropTypes.object,
    })
  ).isRequired,
  orientation: PropTypes.oneOf(['row', 'column']),
}
ContactBox.defaultProps = {
  orientation: 'row',
}

export default ContactBox
