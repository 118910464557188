import React from 'react'
import PropTypes from 'prop-types'
import { AppBar as MAppBar } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const AppBar = ({ children, backgroundColor, shadow, position }) => {
  const Appb = withStyles(() => ({
    root: {
      ...(!shadow && { boxShadow: 'none' }),
      backgroundColor,
    },
  }))(MAppBar)
  return <Appb position={position}>{children}</Appb>
}

AppBar.propTypes = {
  children: PropTypes.node,
  backgroundColor: PropTypes.string,
  shadow: PropTypes.bool,
  position: PropTypes.oneOf(['absolute', 'fixed', 'relative', 'static', 'sticky']),
}

AppBar.defaultProps = {
  children: null,
  backgroundColor: 'white',
  shadow: false,
  position: 'relative',
}
export default AppBar
