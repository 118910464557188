import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { yupResolver } from '@hookform/resolvers/yup'
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import * as Yup from 'yup'
import { Button, Input, Link } from '../../atoms'

const useStyles = makeStyles(theme => ({
  button: {
    color: theme.palette.common.black,
    padding: '10px 45px',
    borderRadius: '2px',
    fontSize: '0.875rem',
  },
  input: {
    width: '65%',
  },
  label: {
    color: theme.palette.textGrey,
  },
  paddingTop: {
    paddingTop: theme.spacing(1),
  },
}))

const LoginForm = ({ onSubmit }) => {
  const classes = useStyles()
  const { t } = useTranslation('auth')

  const loginSchema = Yup.object().shape({
    password: Yup.string().required(t('required')),
    email: Yup.string().email(t('invalidEmail')).required(t('required')),
  })

  const methods = useForm({ resolver: yupResolver(loginSchema) })

  const {
    handleSubmit,
    formState: { errors },
    register,
  } = methods
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid xs={12} item>
          <Typography variant="h2">{t('login')}</Typography>
        </Grid>
        <Grid xs={12} item>
          <Input
            {...register('email')}
            label={t('email')}
            error={errors?.email?.message}
            helperText={errors?.email?.message}
            small
            className={classes.input}
            labelClassName={classes.label}
          />
        </Grid>
        <Grid xs={12} item>
          <Input
            {...register('password')}
            label={t('password')}
            error={errors?.password?.message}
            helperText={errors?.password?.message}
            type="password"
            small
            className={classes.input}
            labelClassName={classes.label}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <Link to="/forgot-password">
            <Typography variant="body1">{t('forgotPassword.title')}</Typography>
          </Link>
          <Link to="/resend-confirmation-mail">
            <Typography className={classes.paddingTop} variant="body1">
              {t('resendConfirmationMail.resend')}
            </Typography>
          </Link>
        </Grid>
        <Grid item>
          <Button type="submit" color="primary" className={classes.button}>
            {t('loginAction')}
          </Button>
        </Grid>
        <Grid xs={12} item>
          <Typography variant="body2">{t('mandatoryFields')}</Typography>
        </Grid>
      </Grid>
    </form>
  )
}
LoginForm.propTypes = {
  onSubmit: PropTypes.func,
}
LoginForm.defaultProps = {
  onSubmit: () => {},
}

export default LoginForm
