import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { QuestionMarkOutlined } from '../../../assets/icons'
import Text from '../Text'

const useStyles = makeStyles(theme => ({
  title: {
    paddingBottom: theme.spacing(1),
  },
  noMaxWitdh: {
    maxWidth: 'none',
  },
  tooltip: {
    backgroundColor: 'white',

    boxShadow: '0 6px 16px -8px black',
  },
  marginLeft: {
    marginLeft: 20,
  },
  marginBottom: { marginBottom: 20 },
  titleContent: {
    display: 'flex',
    alignItems: 'center',
  },
  padding: {
    padding: '10px',
  },
}))
const InfoTitle = ({ title, tooltipTitle, tooltipMessage, options }) => {
  const classes = useStyles()
  return (
    <Typography className={clsx(classes.title, options?.titleClassName)} variant="h3">
      <div className={classes.titleContent}>
        {title}

        <Tooltip
          title={
            <div className={clsx(classes.padding, options?.tooltipBodyClassName)}>
              <Typography
                variant="h2"
                color="textSecondary"
                className={options?.tooltipTitleClassName}
              >
                {tooltipTitle}
              </Typography>
              <Text fontSize="0.875rem" $color="textGrey" {...options?.tooltipTextOptions}>
                {tooltipMessage}
              </Text>
            </div>
          }
          className={clsx(classes.noMaxWitdh, classes.whiteBackground, classes.marginLeft)}
          classes={{
            tooltip: classes.tooltip,
          }}
        >
          <QuestionMarkOutlined height="20px" />
        </Tooltip>
      </div>
    </Typography>
  )
}

InfoTitle.propTypes = {
  title: PropTypes.string.isRequired,
  options: PropTypes.shape({
    titleClassName: PropTypes.string,
    tooltipTitleClassName: PropTypes.string,
    tooltipBodyClassName: PropTypes.string,
    tooltipTextOptions: PropTypes.shape({
      $bold: PropTypes.bool,
      $bolder: PropTypes.bool,
      $center: PropTypes.bool,
      $color: PropTypes.string,
      $disabled: PropTypes.bool,
      $italic: PropTypes.bool,
      fontSize: PropTypes.number,
      fontWeight: PropTypes.number,
      lineHeight: PropTypes.number,
    }),
  }),
  tooltipTitle: PropTypes.string.isRequired,
  tooltipMessage: PropTypes.string.isRequired,
}
InfoTitle.defaultProps = { options: null }

export default InfoTitle
