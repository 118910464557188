import colors from './colors'

const overrides = {
  MuiFormGroup: {
    row: {
      '& label': {
        paddingRight: 20,
        alignSelf: 'center',
        paddingBottom: 0,
      },
    },
  },
  MuiFormLabel: {
    root: {
      display: 'block',
      fontSize: '0.875rem',
      fontWeight: 600,
      lineHeight: 1.2,
      paddingBottom: 10,
      '&.Mui-focused': {
        color: colors.textGrey,
      },
    },
  },
  MuiOutlinedInput: {
    input: {
      padding: '20px 15px',
    },
  },
  MuiPaginationItem: {
    page: {
      color: colors.brown,
      '&.Mui-selected': {
        background: colors.primaryColor,
      },
    },
    rounded: {
      border: `1px solid ${colors.primaryColor}`,
    },
  },
  MuiDataGrid: {
    root: {
      border: '1px solid white',
    },
  },
}
export default overrides
