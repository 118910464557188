import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import Dieter from '../assets/images/dieter.jpg'
import Lina from '../assets/images/lina.jpg'
import { ContactBox } from '../components/organisms'

const useStyles = makeStyles(() => ({
  root: {
    width: '100px',
    height: '100px',
    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
  },
  imgKoeve: {
    objectFit: 'cover',
    objectPosition: '0px 0px',
  },
  imgLina: {
    width: '160px ',
    height: '160px ',
    objectPosition: '-20px 22px',
  },
}))
const ContactBoxWithData = ({ orientation }) => {
  const classes = useStyles()
  const contacts = [
    {
      name: 'Rechtsanwalt Dieter Koeve',
      email: 'pamira@raekoeve.de',
      phone: '+49 6172 9021-28',
      jobTitle: 'Treuhänder PAMIRA',
      imgSrc: Dieter,
      imgAlt: 'Dieter Koeve',
      imgOptions: {
        classes: { img: classes.imgKoeve, root: classes.root },
      },
    },
    {
      name: 'Lina Harisch',
      email: 'pamira@raekoeve.de',
      phone: '+49 6172 9021-29',
      jobTitle: 'Key Accountant PAMIRA',
      imgSrc: Lina,
      imgOptions: {
        classes: { img: classes.imgLina, root: classes.root },
      },
      imgAlt: 'Lina Harisch',
    },
  ]
  return <ContactBox orientation={orientation} contacts={contacts} />
}
ContactBoxWithData.propTypes = {
  orientation: PropTypes.oneOf(['column', 'row']),
}
ContactBoxWithData.defaultProps = {
  orientation: undefined,
}
export default ContactBoxWithData
