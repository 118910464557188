import React, { useState, useContext, Suspense } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import queryString from 'query-string'
import { CookieBanner } from '../components/atoms'
import { GlobalErrorHandler } from '../components/molecules'
import Layout from '../layouts'
import { isLogin } from '../utils/helper'
import localeContext from '../utils/LocaleContext'
import PrivateRoute from './PrivateRoute'
import PublicRoute from './PublicRoute'
import routes from './routes'

const Router = () => {
  const [logged, setLogged] = useState(false)
  const { language, setLanguage, availableLanguages } = useContext(localeContext)
  return (
    <BrowserRouter>
      <Suspense fallback={null}>
        <CookieBanner />
      </Suspense>
      <Layout logged={logged}>
        <Route
          render={state => {
            const { location } = state
            if (location.search) {
              const { locale } = queryString.parse(location.search)
              if (language?.name !== locale && availableLanguages.find(l => l.value === locale)) {
                setLanguage(locale)
              }
            }
            return (
              <Switch location={location}>
                {setLogged(isLogin())}
                {routes.map(({ path, Component, exact, isPublic, restricted }) =>
                  isPublic ? (
                    <PublicRoute
                      key={path}
                      restricted={restricted}
                      component={Component}
                      path={path}
                      exact={exact}
                    />
                  ) : (
                    <PrivateRoute key={path} component={Component} path={path} exact={exact} />
                  )
                )}
              </Switch>
            )
          }}
        />
        <GlobalErrorHandler />
      </Layout>
    </BrowserRouter>
  )
}

export default Router
