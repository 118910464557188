import React from 'react'
import PropTypes from 'prop-types'
import { FinalNoticeRadioButton } from '../../atoms'

const FinalNoticeRadioButtonGroup = React.forwardRef(({ onChange, items, value }, ref) => (
  <div ref={ref}>
    {items?.map(item => (
      <FinalNoticeRadioButton value={value} key={item.id} onChange={onChange} notice={item} />
    ))}
  </div>
))
FinalNoticeRadioButtonGroup.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  items: PropTypes.array,
}
FinalNoticeRadioButtonGroup.defaultProps = {
  value: undefined,
  onChange: undefined,
  items: undefined,
}

export default FinalNoticeRadioButtonGroup
