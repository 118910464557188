import React, { useState, useEffect } from 'react'
import { FormControl, MenuItem, FormLabel, FormGroup, FormHelperText } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ArrowDown } from 'assets/icons'
import clsx from 'clsx'
import CustomSelect from './CustomSelect'

export interface ISelectItem {
  value: any
  label: string
}

interface Props {
  children?: React.ReactNode
  disabled?: boolean
  italic?: boolean
  items?: ISelectItem[]
  label?: string
  className?: string
  minWidth?: string | number
  maxWidth?: string | number
  multiple?: boolean
  borderless?: boolean
  onChange?(value?: any): void
  value?: any
  placeholder?: string
  helperText?: string
  labelPlacement?: 'row' | 'column'
  selectClass?: string
  labelClass?: string
}

type SelectChangeEvent = {
  value: any
}

const useStyles = makeStyles(() => ({
  italic: {
    '& > .MuiInputLabel-outlined': {
      fontStyle: 'italic',
    },
  },
  root: {
    '& label + .MuiInput-formControl': {
      marginTop: 12,
    },
  },
  borderless: {
    fontWeight: 'bold',
    '& fieldset': {
      border: 'none',
    },
  },
}))

const ArrowDownComponent: React.FC<any> = props => (
  <ArrowDown {...props} style={{ top: 'inherit' }} />
)

const Select = React.forwardRef<typeof FormControl, Props>(
  (
    {
      children,
      disabled,
      italic,
      items,
      label,
      minWidth = 100,
      maxWidth = 180,
      multiple,
      onChange,
      value: propValue,
      placeholder,
      className,
      helperText,
      labelPlacement,
      borderless,
      selectClass,
      labelClass,
    },
    ref
  ) => {
    const classes = useStyles()
    const [value, setValue] = useState(propValue)

    useEffect(() => {
      if (propValue !== value) setValue(propValue)
    }, [propValue, setValue, value])

    const placeholderObject = { label: <em>{placeholder}</em>, value: '' }
    const itemsWithPlaceholder = placeholder ? [placeholderObject, ...(items || [])] : items
    const handleChange = (event?: React.ChangeEvent<SelectChangeEvent>) => {
      const { target } = event || {}
      const { value: newValue } = target || {}
      onChange?.(newValue)
    }

    return (
      // @ts-ignore
      <FormControl
        className={clsx(className, italic ? classes.italic : classes.root)}
        variant="outlined"
        style={{ minWidth }}
        ref={ref}
      >
        <FormGroup aria-label="position" row={labelPlacement === 'row'}>
          <FormLabel className={labelClass} component="label">
            {label}
          </FormLabel>
          <CustomSelect
            className={clsx(borderless ? classes.borderless : undefined, selectClass)}
            id="select-outlined"
            maxWidth={maxWidth}
            minWidth={minWidth}
            multiple={multiple}
            value={value}
            onChange={handleChange}
            disabled={disabled}
            displayEmpty
            IconComponent={ArrowDownComponent}
          >
            {items?.length
              ? itemsWithPlaceholder?.map(item => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))
              : children}
          </CustomSelect>
        </FormGroup>
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    )
  }
)

export default Select
