import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { yupResolver } from '@hookform/resolvers/yup'
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import * as Yup from 'yup'
import { Button, Input } from '../../atoms'

const useStyles = makeStyles(theme => ({
  button: {
    color: theme.palette.common.black,
    padding: '10px 45px',
    borderRadius: '2px',
    fontSize: '0.875rem',
  },
  input: {
    width: '65%',
  },
  label: {
    color: theme.palette.textGrey,
  },
}))

const ResetPasswordForm = ({ onSubmit }) => {
  const classes = useStyles()
  const { t } = useTranslation('auth')

  const validationSchema = Yup.object().shape({
    password: Yup.string().min(8, t('passwordMustHave8Chars')).required(t('required')),
    passwordConfirmation: Yup.string()
      .required(t('required'))
      .test(
        'passwordConfirmation',
        t('passwordsMustMatch'),
        (value, { parent: { password } }) => value === password
      ),
  })

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    reValidateMode: 'onBlur',
  })

  const {
    handleSubmit,
    formState: { errors },
    register,
  } = methods
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid xs={12} item>
          <Typography variant="h2">{t('resetPassword.title')}</Typography>
        </Grid>
        <Grid xs={12} item>
          <Input
            {...register('password')}
            label={t('password')}
            error={errors?.password?.message}
            helperText={errors?.password?.message}
            small
            type="password"
            className={classes.input}
            labelClassName={classes.label}
            required
          />
        </Grid>
        <Grid xs={12} item>
          <Input
            {...register('passwordConfirmation')}
            label={t('passwordConfirmation')}
            error={errors?.passwordConfirmation?.message}
            helperText={errors?.passwordConfirmation?.message}
            small
            type="password"
            className={classes.input}
            labelClassName={classes.label}
            required
          />
        </Grid>
        <Grid item>
          <Button type="submit" color="primary" className={classes.button}>
            {t('resetPassword.buttonText')}
          </Button>
        </Grid>
        <Grid xs={12} item>
          <Typography variant="body2">{t('mandatoryFields')}</Typography>
        </Grid>
      </Grid>
    </form>
  )
}
ResetPasswordForm.propTypes = {
  onSubmit: PropTypes.func,
}
ResetPasswordForm.defaultProps = {
  onSubmit: () => {},
}

export default ResetPasswordForm
